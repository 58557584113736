import { mapActions, mapGetters, mapState } from 'vuex';
import get from 'lodash/get';
import { nameSpace as companyNameSpace } from '@/vue/stores/Company/CompanyStore';
import {
  ACTIONS as companySettingsActions,
  NAMESPACE as companySettingsNamespace,
} from '@/stores/Company/UserCompanyAccount/CompanySettingsModule';

import {
  ACTIONS as userCompanySettingsActions,
  NAMESPACE as userCompanySettingsNamespace,
  SCOPES as userCompanySettingsScopes,
} from '@/stores/Company/UserCompany/UserCompanySettingsModule';
import { getValue } from '@emobg/web-utils';

export const {
  addKeyCardStatus,
  deactivateUserStatus,
  employeePolicy,
  editLicenseExpirationDateStatus,
  inviteAnEmployeeStatus,
  removeKeyCardStatus,
  getCompanyData,
  isOpenOrDedicatedFleet,
  userHasCompany,
  postInviteAnEmployee,
  putToggleActiveEmployee,
  putCancelEmployeeBadge,
  putAddEmployeeBadge,
  getEmployeePolicy,
  putEditLicenseExpirationDate,
  setCompanyData,
  resetCompanyStore,
  putUpdateCompany,
  companyUuid,
  companyAllowsAdditionalDriver,
  keyCardBadgeListStatus,
  getKeyCardBadgeList,
  getEmployeeList,
  employeeList,
  keyCardList,
} = {
  ...mapState(userCompanySettingsNamespace, {
    employeePolicy: ({ data }) => get(data, 'content'),
    keyCardList: state => get(state, `${userCompanySettingsScopes.keyCardBadgeList}.data.data`),
    employeeList: state => getValue(state, `${userCompanySettingsScopes.employeeList}.data.data`, []),
    inviteAnEmployeeStatus: state => get(state, `${userCompanySettingsScopes.inviteAnEmployee}.STATUS`),
    editLicenseExpirationDateStatus: state => get(state, `${userCompanySettingsScopes.editLicenseExpirationDate}.STATUS`),
    deactivateUserStatus: state => get(state, `${userCompanySettingsScopes.deactivateUser}.STATUS`),
    addKeyCardStatus: state => get(state, `${userCompanySettingsScopes.addKeyCard}.STATUS`),
    removeKeyCardStatus: state => get(state, `${userCompanySettingsScopes.removeKeyCard}.STATUS`),
  }),

  ...mapGetters(companyNameSpace, [
    'getCompanyData',
    'userHasCompany',
    'companyUuid',
    'companyAllowsAdditionalDriver',
    'isOpenOrDedicatedFleet',
  ]),

  ...mapActions(userCompanySettingsNamespace, [
    userCompanySettingsActions.getEmployeePolicy,
    userCompanySettingsActions.postInviteAnEmployee,
    userCompanySettingsActions.putEditLicenseExpirationDate,
    userCompanySettingsActions.putToggleActiveEmployee,
    userCompanySettingsActions.putCancelEmployeeBadge,
    userCompanySettingsActions.putAddEmployeeBadge,
    userCompanySettingsActions.getKeyCardBadgeList,
    userCompanySettingsActions.getEmployeeList,
  ]),

  ...mapActions(companyNameSpace, [
    'setCompanyData',
    'resetCompanyStore',
  ]),

  ...mapActions(companySettingsNamespace, [
    companySettingsActions.putUpdateCompany,
  ]),
};
