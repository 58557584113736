import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import Intercom, {
  boot,
  hide,
  shutdown,
  update,
} from '@intercom/messenger-js-sdk';
import { REGIONS_LOCALES, getValue, pickValue } from '@emobg/web-utils';
import BookingRouteNames from '@Bookings/router/routes-names';
import personalProfileRouteNames from '@Profile/Personal/router/routes-names';
import companyAdminRouteNames from '@Profile/Business/CompanyAdmin/router/routes-names';

const REQUIRED_VENDORS_CHAT_BOT = ['c:intercom-b4JnH3Bp'];

/**
 * Checks if chatbot matches the conditions to be shown
 * @param enabledVendors
 * @param isPersonalProfileActive
 * @param allowsPersonalUseOfOpen
 * @returns {boolean}
 */
export const isChatBotEnabled = enabledVendors => {
  const intersectionConsents = intersection(enabledVendors, REQUIRED_VENDORS_CHAT_BOT);

  return REQUIRED_VENDORS_CHAT_BOT.length === intersectionConsents.length;
};

/**
 * Checks if the route allows to display the chatbot or not
 * @param routeName
 * @returns {*}
 */
export const isChatVisibleOnRoute = routeName => {
  const {
    home, myBookings, myBookingsActive, myBookingsHistory,
  } = BookingRouteNames;

  const chatBotRoutes = [
    personalProfileRouteNames.invoices,
    companyAdminRouteNames.invoices,
    home,
    myBookings,
    myBookingsActive,
    myBookingsHistory,
  ];

  return includes(chatBotRoutes, routeName);
};

/**
 * Closes user session on chat and removes related window data to avoid relaunching it
 */
const destroyChatBot = () => {
  if (window.intercomSettings) {
    shutdown();
    window.intercomSettings = undefined;
  }
};

/**
 * Updates current chatbot user data.
 * In case user changes profile or language new session is started using `shutdown()` SDK method.
 * @param paramsToUpdate
 */
export const updateChatBotParams = paramsToUpdate => {
  if (window.intercomSettings) {
    const isDifferentLanguage = getValue(paramsToUpdate, 'locale', window.intercomSettings.locale) !== window.intercomSettings.locale;
    const isDifferentProfile = getValue(paramsToUpdate, 'profile_type', window.intercomSettings.profile_type) !== window.intercomSettings.profile_type;
    const shouldResetSession = isDifferentLanguage || isDifferentProfile;
    const intercomMethod = shouldResetSession ? shutdown : paramsToUpdate.hide_default_launcher === true ? hide : () => {};
    intercomMethod();
    window.intercomSettings = {
      ...window.intercomSettings,
      ...paramsToUpdate,
    };
    update(window.intercomSettings);
  }
};

/**
 * Inits Intercom chatbot or destroys if it doesn't match the conditions
 * @param appId
 * @param enabledVendors
 * @param userData
 * @param profileType
 * @param fleetType
 * @param operatorUuid
 * @param isPersonalProfileActive
 * @param routeName
 */
export const initChatBot = ({
  appId, enabledVendors, userData, profileType, fleetType, operatorUuid, routeName,
}) => {
  if (appId && isChatBotEnabled(enabledVendors)) {
    const intercomParams = {
      ...pickValue(userData ?? [], ['email', 'country', 'locale']),
      app_id: appId,
      name: `${getValue(userData, 'firstName', '')} ${getValue(userData, 'lastName', '')}`,
      profile_type: profileType,
      operator_uuid: operatorUuid,
      hide_default_launcher: !isChatVisibleOnRoute(routeName),
      fleet: fleetType,
      language_override: getValue(userData, 'locale', REGIONS_LOCALES.en),
    };
    const triggerChatBot = !window.Intercom ? Intercom : boot;
    triggerChatBot(intercomParams);
    window.intercomSettings = intercomParams;
  } else {
    destroyChatBot();
  }
};
